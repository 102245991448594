//react
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//components
import FollowerPopupModel from "../FollowerPopupModel/FollowerPopupModel";
//context
import { useAuth } from "../../context/AuthContext";
//api
import {
  getListOfFollowers,
  postIsFollowing,
  postFollowOrUnfollow,
} from "../../api/users";
import { postGetOrCreateRoomId } from "../../api/chat";
//type
import { TypeUserInfo, TypeFollower, TypeCountsInfo } from "../../types/user";
//style
import "./ProfileCard.scss";

interface ProfileCardProps {
  userInfo: TypeUserInfo;
  userId: string;
  countsInfo: TypeCountsInfo;
}

const ProfileCard: React.FC<ProfileCardProps> = (props) => {
  const [followers, setFollowers] = useState<TypeFollower[]>([]);
  const [isFollowing, setIsFollowing] = useState();
  const [fetchAgain, setFetchAgain] = useState(false);

  // context - memberId
  const { currentMember, isAuthenticated, setShowAuth } = useAuth();

  const location = useLocation();
  const { pathname } = location;

  const [isShow, setIsShow] = useState(false);

  // check/create chatroom Id and navigate to chatroom
  const navigate = useNavigate();

  const handleLaunchRoom = async () => {
    try {
      const ids = await postGetOrCreateRoomId({
        senderId: currentMember?.currentMemberId, // current user
        receiverId: props?.userId,
      });

      if (ids) {
        navigate("/message-main", {
          state: { chatroomIds: ids },
        });
      }
    } catch (error) {
      return error;
    }
  };

  const handleFollowOrUnFollow = async () => {
    try {
      const res = await postFollowOrUnfollow({
        FollowerId: currentMember?.currentMemberId, //自己
        FollowedId: props?.userId, //要追蹤對象
      });

      if (res?.success) {
        setFetchAgain(!fetchAgain);
      }
    } catch (error) {
      return error;
    }
  };

  // List of Followers
  useEffect(() => {
    const getFollowersAsync = async () => {
      try {
        const list = await getListOfFollowers(props?.userId);
        setFollowers(list);
      } catch (error) {
        return error;
      }
    };

    getFollowersAsync();
  }, [fetchAgain, props?.userId]);

  // Check if current user is following the id of other user
  useEffect(() => {
    // make sure its not on current user's profile page
    if (props?.userId !== currentMember?.currentMemberId) {
      const postIsFollowingAsync = async () => {
        try {
          const isFollowing = await postIsFollowing({
            FollowerId: currentMember?.currentMemberId, //自己
            FollowedId: props?.userId, //要追蹤對象
          });
          setIsFollowing(isFollowing);
        } catch (error) {
          return error;
        }
      };

      postIsFollowingAsync();
    }
  }, [fetchAgain, props?.userId]);

  return (
    <>
      <div className="profile-card-container">
        <img
          className="user-bg"
          src={props?.userInfo?.backgroundImage}
          alt="profile-bg"
        />
        <div className="backdrop"></div>
        <div className="card-content">
          <div className="user-pic">
            <img src={props?.userInfo?.personalAvatar} alt="user-pic" />
          </div>
          <div className="content-group">
            <div className="user-detail">
              <span className="user-name sb-24">
                {props?.userInfo?.userName}
              </span>
              <div className="decription r-14">
                {props?.userInfo?.personalIntroduction}
              </div>
            </div>
            <div className="social-counts">
              <div className="post-count">
                <span className="b-14">{props?.countsInfo?.memberPosts}</span>
              </div>
              <div className="vertical-bar"></div>
              <div className="follower-count" onClick={() => setIsShow(true)}>
                <span className="b-14">{props?.countsInfo?.memberFans}</span>
              </div>
              <div className="vertical-bar"></div>
              <div className="like-count">
                <span className="b-14">{props?.countsInfo?.postLikes}</span>
              </div>
            </div>
          </div>
        </div>
        {pathname.includes("/userinfo") && (
          <div className="profile-btn-group">
            <button
              className={`sb-16 ${!isFollowing && "to-follow"}`}
              onClick={() =>
                isAuthenticated ? handleFollowOrUnFollow() : setShowAuth?.(true)
              }
            >
              {isFollowing ? "取消關注" : "關注"}
            </button>
            <button
              className="sb-16"
              onClick={() =>
                isAuthenticated ? handleLaunchRoom() : setShowAuth?.(true)
              }
            >
              訊息
            </button>
          </div>
        )}
      </div>
      {isShow && (
        <FollowerPopupModel
          followers={followers}
          setIsShow={(close: boolean) => setIsShow(close)}
        />
      )}
    </>
  );
};

export default ProfileCard;
