import axios from "axios";
import { baseUrl } from "./axiosInstance";
//type
import { TypeUserInfoSubmit } from "../types/user";

// api to get users profile information
export const getUserInfo = async (userId: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${baseUrl}/app/member/get-member?Id=${userId}`);

    return data;
  } catch (error) {
    return error;
  }
};

// api to update users profile information
export const postUpdateUserInfo = async ({
  id,
  UserName,
  PersonalIntroduction,
  personalAvatar,
  backgroundImage,
  backgoundImageMobile,
  businessCard,
}: TypeUserInfoSubmit) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/member/update-member-api`,
      {
        id,
        UserName,
        PersonalIntroduction,
        personalAvatar,
        backgroundImage,
        backgoundImageMobile,
        businessCard,
      },
      { headers: { "Content-type": "multipart/form-data" } }
    );

    return data.success;
  } catch (error) {
    return error;
  }
};

// api to get list of followers of an user
export const getListOfFollowers = async (userId: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/follower/get-listOfMemberWhoFollowMe?currentMemberId=${userId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to check if current user is following a certain user
export const postIsFollowing = async ({
  FollowerId, //追蹤者
  FollowedId, //要追蹤的對象
}: {
  FollowerId: string;
  FollowedId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/follower/is-currently-following?FollowerId=${FollowerId}&FollowedId=${FollowedId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to follow or unfollow a user
export const postFollowOrUnfollow = async ({
  FollowerId, //追蹤者
  FollowedId, //要追蹤的對象
}: {
  FollowerId: string;
  FollowedId: string;
}) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${baseUrl}/app/follower/follow-member?FollowerId=${FollowerId}&FollowedId=${FollowedId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to get users counts of follower, Post and Like
export const getUserCountsInfo = async ({
  memberId,
  includePrivate,
}: {
  memberId: string;
  includePrivate: boolean;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/member/get-FansPostsLikesCount-api?memberId=${memberId}&includePrivate=${includePrivate}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to get users notification
export const getUserNotification = async (memberId: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/userNotifications/get-MonthsNotificationsByMemberId?memberId=${memberId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to update notification to read one
export const postIsReadNotifi = async (notificationId: string) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/userNotifications/update-Notifications-To-Read?notificationId=${notificationId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to assosiate user id with device token
export const postUpateDeviceToken = async ({
  memberId,
  token,
}: {
  memberId: string;
  token: string;
}) => {
  try {
    const data = await axios.post(
      `${baseUrl}/app/firebase/create-Or-Update-DeviceToken?memberId=${memberId}&token=${token}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to delete user account and data in db
export const postDeleteUserAccount = async (memberId: string) => {
  try {
    const data = await axios.post(
      `${baseUrl}/app/member/delete-member-and-related-data?memberId=${memberId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};
