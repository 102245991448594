//react
import { Dispatch, SetStateAction } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
//api
import { deletePost } from "../../api/posts";
//context
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./PostEditDropDown.scss";

interface PostEditDropDownProps {
  postId: string;
  memberId: string;
  postType: string;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const PostEditDropDown: React.FC<PostEditDropDownProps> = (props) => {
  //context
  const { currentMember } = useAuth();
  const {
    setReportShow,
    setRepostId,
    setReportType,
    setReportedId,
    setContent,
    refetchProfile,
    setRefetchProfile,
    memberId,
  } = usePost();

  // Navigate to page to edit post
  const navigate = useNavigate();

  // Delete post - react query
  const queryClient = useQueryClient();

  // const deletePostMutation = useMutation({
  //   mutationFn: async (id: string) => {
  //     return await deletePost(id);
  //   },
  //   onSuccess: () => {
  //     //queryClient.invalidateQueries("/posts");
  //     props.setIsShow(false); // Close post popup model

  //     queryClient.setQueryData(["/posts"], (prev: any) => {
  //       return prev?.pages?.fiter((pg: TypePost[]) => {
  //         return pg?.filter((post) => {
  //           return post?.id !== props?.postId;
  //         });
  //       });
  //     });
  //   },
  // });

  const handleDeletePost = async () => {
    try {
      const res = await deletePost(props?.postId);

      if (res) {
        props.setIsShow(false); // Close post popup model

        const hasMemberId = memberId ? ["/posts", memberId] : ["/posts"];

        queryClient.setQueryData(hasMemberId, (data: any) => {
          const newData = {
            pages: data?.pages?.map((page: any) =>
              page?.filter((post: any) => post?.id !== props?.postId)
            ),
          };
          return newData;
        });

        setRefetchProfile?.(!refetchProfile);
      }
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="post-dropdown-options">
      {props?.memberId === currentMember?.currentMemberId ? (
        <>
          <div
            className="post-dropdown-action sb-14"
            onClick={() => {
              navigate("/editpost", {
                state: {
                  postId: props?.postId,
                  status: "editing",
                  postType: props?.postType,
                },
              });
            }}
          >
            <img src={TemplateCardImg.srcEdit_icon} alt="edit-icon" />
            <span>編輯</span>
          </div>
          <div
            className="post-dropdown-action sb-14"
            onClick={() => handleDeletePost()}
          >
            <img src={TemplateCardImg.srcDelete_icon} alt="delete-icon" />
            <span>刪除</span>
          </div>
        </>
      ) : (
        <div
          className="post-dropdown-action sb-14"
          onClick={() => {
            setReportShow?.(true);
            setRepostId?.(props?.postId);
            setReportType?.(0);
            setReportedId?.(props?.memberId);
            setContent?.("");
            props.setShow(false); // Close dropdwon menu
          }}
        >
          <img src={TemplateCardImg.srcReport_icon} alt="report-icon" />
          <span>檢舉</span>
        </div>
      )}
    </div>
  );
};

export default PostEditDropDown;
